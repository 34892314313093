import React, { Component, lazy, Suspense } from "react";
import loadable from "@loadable/component";
import "./App.css";
import { CONFIG } from "./shared/siteDetails";
import { GIGS } from "./shared/gigs";
import { VIDEOS } from "./shared/videos";
import { RECORDINGS } from "./shared/recordings";

const SectionFeaturedAlbum = loadable(() =>
  import("./components/SectionFeaturedAlbum")
);
const SectionAlbum = loadable(() => import("./components/SectionAlbum"));
const SectionVideo = loadable(() =>
  import("./components/SectionVideoComponent")
);
const SectionFooter = loadable(() =>
  import("./components/SectionFooterComponent")
);

const renderLoader = () => <p>Loading</p>;

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      config: CONFIG,
      gigs: GIGS,
      videos: VIDEOS,
      recordings: RECORDINGS,
    };
  }

  render() {
    return (
      <Suspense fallback={renderLoader()}>
        <div className="App">
          <SectionFeaturedAlbum
            config={this.state.config}
            recording={this.state.recordings[4]}
          />
          <SectionAlbum recording={this.state.recordings[3]} />
          <SectionAlbum recording={this.state.recordings[2]} />
          <SectionVideo video={this.state.videos[2]} />
          <SectionAlbum recording={this.state.recordings[1]} />
          {/*<SectionVideo video={this.state.videos[1]} />*/}
          <SectionAlbum recording={this.state.recordings[0]} />
          <SectionVideo video={this.state.videos[0]} />
          <SectionFooter />
        </div>
      </Suspense>
    );
  }
}

export default App;
