import backgroundV1 from "../assets/images/video_background1.jpg";
import backgroundV1webp from "../assets/images/video_background1-a.webp";
import backgroundV2 from "../assets/images/video_background2.jpg";
import backgroundV2webp from "../assets/images/video_background2.webp";
import backgroundV3 from "../assets/images/video_background3.jpg";
import backgroundV3webp from "../assets/images/video_background3.webp";

export const VIDEOS = [
  {
    id: 1,
    name: "ID0001: Kising The Uknown",
    url: "AXjt8oKVhWE",
    background: backgroundV1,
    backgroundwebp: backgroundV1webp,
  },
  {
    id: 2,
    name: "ID0002: The Bite, The Hit, The Explosion",
    url: "EmdXz5ui08E",
    background: backgroundV2,
    backgroundwebp: backgroundV2webp,
  },
  {
    id: 3,
    name: "ID0003: The Answer Is In The Code",
    url: "p65KGDaow7c",
    background: backgroundV3,
    backgroundwebp: backgroundV3webp,
  },
];
