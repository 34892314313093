import backgroundR1 from "../assets/images/id001_background.jpg";
import backgroundR1webp from "../assets/images/id001_background.webp";
import coverR1 from "../assets/images/id0001-ep.jpg";

import backgroundR2 from "../assets/images/id002_background.jpg";
import backgroundR2webp from "../assets/images/id002_background.webp";
import coverR2 from "../assets/images/id0002-ep-600.jpg";

import backgroundR3 from "../assets/images/id003_background.jpg";
import backgroundR3webp from "../assets/images/id003_background.webp";
import coverR3 from "../assets/images/id0003-ep-600.jpg";

import backgroundR4 from "../assets/images/id004_background.jpg";
import backgroundR4webp from "../assets/images/id004_background.webp";
import coverR4 from "../assets/images/id0004-ep-600.jpg";

import backgroundR5 from "../assets/images/id005_background.jpg";
import backgroundR5webp from "../assets/images/id005_background.webp";
import coverR5 from "../assets/images/id0005-ep-600.jpg";


export const RECORDINGS =
        [{
            id: 1,
            title_id: 'ID001',
            name: 'Hunted Robots and Other Tales',
            spotify_url: 'https://open.spotify.com/album/4adZS8umd1tiUctr4GihlA?si=XxJKB2YQR0uy08XqSS6qXg', 
            background: backgroundR1,
            backgroundwebp: backgroundR1webp,
            cover: coverR1,
            year: 2020
        },
        {
            id: 2,
            title_id: 'ID002',
            name: 'The Bite, The Hit, The Explosion',
            spotify_url: 'https://open.spotify.com/album/740YmeXsN6SG6URLYFE2mV?si=HlWLdBSPQO2gP0lT6WIPuAz5ui08E',
            background: backgroundR2,
            backgroundwebp: backgroundR2webp,
            cover: coverR2,
            year: 2021
        },
        {
            id: 3,
            title_id: 'ID003',
            name: 'The Answer Is In The Code',
            urspotify_url: 'https://open.spotify.com/album/740YmeXsN6SG6URLYFE2mV?si=HlWLdBSPQO2gP0lT6WIPuAz5ui08E',
            background: backgroundR3,
            backgroundwebp: backgroundR3webp,
            cover: coverR3,
            year: 2021
        },
        {
            id: 4,
            title_id: 'ID0004',
            name: 'Tomorrow',
            urspotify_url: 'https://open.spotify.com/album/48kxhE66bnDjGjslYCD7qX?si=TPzjS5XfTBKc4k-g_cQjeg',
            background: backgroundR4,
            backgroundwebp: backgroundR4webp,
            cover: coverR4,
            year: 2021
            
        },
        {
            id: 5,
            title_id: 'ID0005',
            name: 'Fade Into The Daemons',
            urspotify_url: 'https://open.spotify.com/album/2TpmpT62GE2J3gGKNMRE7R?si=gSD031eHQm-VQ6oFoWOZtA',
            background: backgroundR5,
            backgroundwebp: backgroundR5webp,
            cover: coverR5,
            year: 2022
        }
    ];